@import "~@styles/variables.scss";
@import 'tocbot.css';
@import 'swiper/scss';
@import 'swiper/css/pagination';
@import 'react-toastify/dist/ReactToastify.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  color: #000000;
  font-family: Mulish, sans-serif;
}

//Lock copy & paste on HTML
body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header {
  position: sticky;
  top: 0;
  @apply z-50 bg-white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

div[onclick] {
  @apply cursor-pointer;
}

.fix-content {
  min-height: 300px;
  @apply flex flex-1 flex-grow;
}

#__next {
  min-height: 100vh;
  @apply flex flex-1 flex-col;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.swiper-pagination-bullet {
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  @apply w-5px h-5px lg:w-8px lg:h-8px;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #2459ad;
}
.example-thumb {
  font-size: 11px;
  text-align: center;
  color: #222;
  cursor: pointer;
}
.example-thumb.active {
  background-color: grey;
}
.example-track {
  position: relative;
  background: #dedede;
  height: 4px;
}
.example-track.example-track-1 {
  background: #2459ad;
}
.example-track.example-track-2 {
  background: #dedede;
}
.horizontal-slider .example-track {
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: -10px;
  width: 70px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #fff;
  border-width: 1px;
  border-color: #dedede;
  border-style: solid;
  border-radius: 4px;
}
.horizontal-slider-advance-search .example-thumb {
  top: -10px;
  width: 72px;
  height: 22px;
  padding: 2px;
  background: #fff;
  border-width: 1px;
  border-color: #2459ad;
  border-style: solid;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.horizontal-slider-advance-search_v2 .example-thumb {
  top: -10px;
  width: 22px;
  height: 22px;
  background: #fff;
  border-width: 1px;
  border-color: #2459ad;
  border-style: solid;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
}

.animated-gradient-lighter {
  background: repeating-linear-gradient(
    to right,
    #d7d7d7 0%,
    #fff 50%,
    #d7d7d7 100%
  );
  width: 100%;
  background-size: 200% auto;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  border-radius: 3px;
  height: 15px;
}
::-webkit-scrollbar {
  //width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #80888f;
}

.flexRow {
  flex-direction: row !important;
}

.flexCol {
  flex-direction: column !important;
}

.headerOnMobile {
  display: none !important;
}

@media (min-width: 1024px) {
  .headerOnMobile {
    display: flex !important;
  }
}

.leaflet-container {
  //background: #f2f2f2;
  outline: 0;
}

.table {
  @apply bg-blue-main;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__toast {
  padding: 0 !important;
}

.Toastify__toast--default {
  background-color: transparent;
}
.txtOneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.datepicker {
  height: 100%;
  & .datepicker-content {
    // height: 100%;
    & .datepicker-col-1 {
      & .datepicker-viewport {
        height: 350px;
      }
    }
  }
  &.ios {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .datepicker-content {
      padding-top: 1px !important;
    }
    & .datepicker-navbar-btn {
      color: white !important;
    }
    & .datepicker-navbar {
      position: sticky !important;
      bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: unset !important;
      padding: 0 0 30px 0 !important;

      & a:last-child {
        display: none;
      }
      & a.datepicker-navbar-btn {
        width: 90%;
        background: #275aa9;
        border-radius: 10px;
      }
    }
  }
}
