@import "~@styles/variables.scss";
.buttonContainer {
  @apply w-full flex flex-row items-center justify-center h-42px rounded-rd8px px-8px;
}

.primaryButton {
  @apply bg-blue-main;
}
.secondaryButton {
  border: 1px solid #2459ad;
  @apply bg-white;
}
.secondaryAddButton {
  border: 1px dashed #2459ad;
  @apply bg-white;
}

.redButton {
  @apply bg-red-600;
}
.secondaryAddLabel {
  @apply text-14 text-blue-main font-bold font-textBold;
}
.primaryLabel {
  @apply text-14 text-white font-bold font-textBold;
}

.secondaryLabel {
  @apply text-14 text-black font-bold font-textBold;
}

.redLabel {
  @apply text-14 text-white font-bold font-textBold;
}
