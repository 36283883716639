.heading1 {
  font-size: 36px;
  @apply font-textBold font-bold text-black
}

.heading2 {
  font-size: 24px;
  @apply font-textBold font-bold text-black
}

.heading3 {
  font-size: 18px;
  @apply font-textBold font-bold text-black
}

.heading4 {
  font-size: 16px;
  @apply font-textBold font-bold text-black
}

.heading5 {
  font-size: 14px;
  @apply font-textBold font-bold text-black
}

.bodyText {
  font-size: 14px;
  @apply font-text text-black
}

.boldBodyText {
  font-size: 14px;
  @apply font-textBold font-bold text-black
}
