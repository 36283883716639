.container {
  border-radius: 4px 4px 0 0;
  height: calc(100vh - 200px);
  max-height: 600px;
  z-index: 1000;
  @apply w-690px fixed border border-blue-main flex flex-col  bg-white bottom-0 left-20
}

.headerChat {
  border-radius: 4px 4px 0 0;
  @apply w-full p-10px bg-blue-main text-16 text-white font-textBold font-bold
}

.closeButton {
  @apply absolute right-8px top-8px cursor-pointer
}

.listConversation {
  @apply w-256px flex flex-col border-r border-gray-500
}

.rightChat {
  @apply flex-1 flex-col flex
}

.selectionContainer {
  max-height: 300px;
  @apply w-280px bg-white border border-blue-main rounded-rd8px overflow-y-auto overflow-x-hidden
}

.broker {
  @apply w-full flex flex-row p-10px border-t border-gray-500 items-center
}

.broker:first-child {
  @apply border-t-0
}

.avatar {
  min-width: 32px;
  width: 32px;
  height: 32px;
}

.conversation {
  min-height: 53px;
  @apply flex flex-row border-b border-blue-main items-center px-8px  relative
}
.conversation:first-child {
  @apply border-t
}
.message {
  max-width: 250px;
  @apply rounded-rd8px p-8px text-white font-text text-14 break-words
}

.messageLoading {
  width: 150px;
  @apply rounded-rd8px p-8px flex flex-col
}

.containerMessage {
  max-height: 95px;
  @apply w-full rounded-rd16px border border-blue-main flex flex-row
}

.input {
  outline:none!important;
  border:none !important;
  resize: none;
  background-color: transparent;
  min-height: 40px;
  max-height: 95px;
  @apply w-full text-14 h-full text-13 font-text rounded-rd16px px-2
}

.input::placeholder {
  color: #2459ad;
}

.image {
  width: 60px !important;
  height: 60px !important;
  @apply bg-no-repeat bg-center bg-cover border border-gray-500 rounded-rd8px
}

.containerMoreAction {
  @apply w-full sm:w-175px rounded-rd4px border-0 sm:border border-gray-400 bg-white space-y-1 overflow-hidden table table-fixed
}

.itemMore {
  @apply flex items-center cursor-pointer p-8px truncate
}
.itemMore:hover{
  background-color: #2459ad;
  color: white;
}

.buttonMore {
 @apply w-24px flex-row items-center justify-center z-10
}

.moreIcon {
  @apply w-20px h-20px
}

.itemMessageMoreAction{
  @apply text-sm text-center cursor-pointer p-1
}
.itemMessageMoreAction:hover{
  background-color: #a0a0a5;
  color: white;
}
