/* purgecss start ignore */

.navigation{
  border-radius:50%;
  z-index:1;
  top:calc(50% - 30px);
  position:absolute;
  height:22px;
  width:22px
}

@media (min-width: 1024px){
  .navigation{
    height:48px;
    width:48px
  }
}

.prev{
  transform:translate(-50%, -30%)
}

@media (min-width: 1024px){
  .prev{
    left:12px
  }
}

.next{
  transform:translate(50%, -30%);
  right:0px
}

@media (min-width: 1024px){
  .next{
    right:12px
  }
}

.iconNext{
  transform:rotate(180deg)
}

/* purgecss end ignore */