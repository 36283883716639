.modalContainer {
  width: calc(100vw - 24px);
  max-height: calc(100vh - 150px);
  @apply bg-white rounded-rd8px flex flex-col relative;
}

.innerUserProfileModal {
  @apply flex lg:flex-row flex-col flex-1 overflow-y-auto items-center lg:items-start px-16px lg:px-0;
}

.avatarContainer {
  @apply w-135px h-135px my-16px lg:my-0;
}

.rightContent {
  @apply border border-blue-main rounded-rd8px flex-1 flex flex-col overflow-hidden mb-24px lg:mb-0;
}

.rowField {
  min-height: 50px;
  @apply flex w-full border-t border-gray-300 items-center px-16px;
}

.rowField:first-child {
  @apply border-t-0;
}

.rowKey {
  min-width: 100px;
  @apply mr-8px;
}

.rowValue {
  word-break: break-word;
  @apply flex-1 text-right flex-1 overflow-hidden;
}

.rejectMemberContainer {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply bg-white rounded-rd8px;
}

.confirmApproveMemberContainer {
  width: calc(100vw - 24px);
  max-width: 375px;
  @apply bg-white rounded-rd8px flex flex-col;
}

.itemMemberWaiting {
  background-color: #fafafa;
  @apply flex p-12px mt-24px;
}

.arrowFilter {
  transform: rotate(0) !important;
}

@media (min-width: 1024px) {
  .modalContainer {
    max-height: calc(100vh - 48px);
    width: 768px;
  }
  .avatarContainer {
    margin-right: 68px;
  }
  .innerUserProfileModal {
    padding: 43px;
  }
  .rejectMemberContainer {
    max-width: 600px;
    @apply w-600px;
  }
  .confirmApproveMemberContainer {
    max-width: 436px;
    @apply w-436px;
  }
  .rowKey {
    min-width: 123px;
    @apply mr-16px;
  }
}
