@import "~@styles/variables.scss";
.navigation {
  border-radius: 50%;
  z-index: 1;
  top: calc(50% - 30px);
  @apply absolute w-22px h-22px lg:w-48px lg:h-48px
}

.prev {
  transform: translate(-50%, -30%);
  @apply lg:left-12px
}

.next {
  transform: translate(50%, -30%);
  @apply right-0 lg:right-12px
}

.iconNext {
  transform: rotate(180deg);
}
